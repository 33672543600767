<template>
  <div>
    <!------------ START: Input group ------------>
    <div ref="inputGroup" class="input-group">
      <div
        class="condition-item form-control text-nowrap p-0"
        :class="[validationClass, isDisabled ? 'disabled' : '']"
      >
        <div class="overflow-x-auto">
          <!------------ START: Field input ------------>
          <autosize-input
            ref="field"
            v-model="$v.condition.field.$model"
            input-class="autosize-input py-2 pl-3 pr-2"
            :placeholder="$t('mapping.selectField')"
            :disabled="isDisabled"
            @input="onInput"
            @focus="onFocus('field')"
            @keyup.esc="showVariables = !showVariables"
            @keydown.tab="showVariables = false"
          />
          <!------------ END: Field input ------------>
          <!------------ START: Operator input ------------>
          <autosize-input
            ref="operator"
            v-model="$v.condition.operator.$model"
            class="position-relative"
            :class="{ invisible: !condition.field }"
            input-class="autosize-input py-2"
            :placeholder="$t('mapping.selectOperator')"
            :disabled="isDisabled"
            readonly
            @focus="showOperators = true"
            @click="showOperators = true"
            @keydown.tab="showOperators = false"
            @input="onInput"
          />
          <!------------ END: Operator input ------------>
          <!------------ START: Value input ------------>
          <autosize-input
            v-if="condition.operator || (condition.operator && condition.field)"
            ref="value"
            v-model="computedValue"
            input-class="autosize-input py-2 pl-2 pr-3"
            :placeholder="$t('mapping.selectValue')"
            :disabled="isDisabled"
            @input="onInput"
            @focus="onFocus('value')"
            @keyup.esc="showVariables = !showVariables"
            @keydown.tab="showVariables = false"
          />
        </div>
        <!------------ END: Value input ------------>
        </div>
      <!------------ START: Return type ------------>
      <div v-if="returnTypeIcon" class="input-group-append">
        <span
          v-b-tooltip.noninteractive.html.top="returnTypeText"
          class="input-group-text border-left-0 bg-white px-2"
        >
          <i :class="[returnTypeIcon]" />
        </span>
      </div>
      <!------------ END: Return type ------------>
      <!------------ START: Clear button ------------>
      <div
        v-b-tooltip.noninteractive.top="$t('formHelper.conditionClear')"
        class="input-group-append"
        :class="{ 'cursor-pointer': !isDisabled }"
        @click="clear"
      >
        <span class="input-group-text">
          <i class="fal fa-delete-left" />
        </span>
      </div>
      <!------------ END: Clear button ------------>
      <!------------ START: Nest button ------------>
      <div
        v-if="nestable"
        v-b-tooltip.noninteractive.top="$t('formHelper.conditionNest')"
        class="input-group-append"
        :class="{ 'cursor-pointer': !isDisabled }"
        @click="nest"
      >
        <span class="input-group-text">
          <i class="fal fa-diagram-nested" />
        </span>
      </div>
      <!------------ END: Nest button ------------>
      <!------------ START: Remove button ------------>
      <div
        v-if="removable"
        v-b-tooltip.noninteractive.top="$t('formHelper.conditionRemove')"
        class="input-group-append"
        :class="{ 'cursor-pointer': !isDisabled }"
        @click="remove"
      >
        <span class="input-group-text">
          <i class="fal fa-trash" />
        </span>
      </div>
      <!------------ END: Remove button ------------>
    </div>
    <!------------ END: Input group ------------>
    <!------------ START: Operator menu ------------>
    <v-menu
      v-if="isMounted"
      v-model="showOperators"
      content-class="mt-9 mw-none"
      :attach="$refs.operator.$el"
      :offset-y="true"
    >
      <v-list dense>
        <v-list-item
          v-for="(operator, i) in operators"
          :key="i"
          class="justify-content-center"
          link
          @click="setOperator(operator)"
        >
          {{ operator }}
        </v-list-item>
      </v-list>
    </v-menu>
    <!------------ END: Operator menu ------------>
    <!------------ START: Variables dropdown ------------>
    <VariablesDropdown
      v-model="showVariables"
      :filter="filter"
      :el="$refs.inputGroup"
      :input-el="activeInput.el"
      @select="setVariable"
    />
    <!------------ END: Variables dropdown ------------>
  </div>
</template>

<script>
import AutosizeInput from "vue-autosize-input";
import VariablesDropdown from "@/components/Tools/FormHelper/Components/VariablesDropdown/VariablesDropdown";
import {
  typeCast,
  typeOf
} from "@/components/Tools/FormHelper/Helper/functions";
import { returnTypeIcons } from "@/components/Tools/FormHelper/Helper/constants";

export default {
  name: "ConditionItem",
  components: { VariablesDropdown, AutosizeInput },
  inject: ["operators"],
  props: {
    value: {
      type: Object,
      default: () => ({
        type: "condition",
        field: "",
        operator: "",
        value: "",
        valid: undefined
      })
    },
    removable: {
      type: Boolean,
      default: false
    },
    nestable: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      condition: {
        type: "condition",
        field: this.value.field,
        operator: this.value.operator,
        value: this.value.value,
        valid: this.value.valid
      },
      isMounted: false,
      showOperators: false,
      showVariables: false,
      activeInput: {
        name: "",
        el: undefined
      }
    };
  },
  validations() {
    return {
      condition: {
        field: { required: value => value !== "" },
        operator: { required: value => value !== "" },
        value: { required: value => value !== "" }
      }
    };
  },
  computed: {
    filter: function () {
      return "";
    },
    // Get correct form validation css class
    validationClass: function () {
      let classText = "";
      if (this.condition.valid) {
        // If field is dirty and invalid
        classText = "is-valid";
      } else if (this.condition.valid === false) {
        // Else if field is dirty and valid
        classText = "is-invalid";
      }
      // Else: if field is not dirty yet, set no class
      return classText;
    },
    computedValue: {
      get: function () {
        // Always get value as string to be editable
        return String(this.$v.condition.value.$model);
      },
      set: function (value) {
        // Set value casted
        this.$v.condition.value.$model = typeCast(value);
      }
    },
    // Get return type icon
    returnTypeIcon: function () {
      return returnTypeIcons[typeOf(this.$v.condition.value.$model)];
    },
    // Get return type text based on current return type
    returnTypeText: function () {
      let prefix = this.$t("formHelper.value"),
        typeText = this.$t(
          "formHelper.returnTypes." + typeOf(this.$v.condition.value.$model)
        );
      return `${prefix}: <span class="font-italic">${typeText}</span>`;
    }
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    // Emit remove event
    remove() {
      if (this.isDisabled) {
        return;
      }
      this.$emit("remove");
    },
    // Clear condition
    clear() {
      if (this.isDisabled) {
        return;
      }
      this.$v.condition.field.$model = "";
      this.$v.condition.operator.$model = "";
      this.$v.condition.value.$model = "";
      this.$v.condition.$reset();
      this.validate();
    },
    // Emit nest event
    nest() {
      if (this.isDisabled) {
        return;
      }
      this.$emit("nest");
    },
    onInput() {
      // Validate condition
      this.validate();
      // Update v-model
      this.$emit("input", this.condition);
    },
    onFocus(name) {
      // Set active input props
      this.activeInput.name = name;
      this.activeInput.el = this.$refs[name].$el;
      // Show variables dropdown
      this.showVariables = true;
    },
    setVariable(variable) {
      // Set variable
      this.$v.condition[this.activeInput.name].$model = variable;
      // Hide variables dropdown
      this.showVariables = false;
      // Update v-model
      this.onInput();
    },
    setOperator(operator) {
      // Set operator
      this.$v.condition.operator.$model = operator;
      // Update v-model
      this.onInput();
    },
    validate() {
      // Set valid with fallback undefined
      let valid = undefined;
      let c = this.$v.condition;
      if (c.field.required && c.operator.required && c.value.required) {
        // If all parts are set
        valid = true;
      } else if (c.field.$error || c.operator.$error || c.value.$error) {
        // Else if at least one part is dirty and invalid
        valid = false;
      }
      // Set valid state
      this.condition.valid = valid;
      return valid;
    }
  }
};
</script>

<style lang="scss">
.autosize-input {
  outline: none;
}
.mw-none {
  max-width: none;
}
</style>
