<template>
  <div>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper :field="field">
      <!------------ START: Condition root group ------------>
      <ConditionGroup
        v-if="isMounted"
        :key="key"
        v-model="value"
        :is-disabled="isDisabled"
      />
      <!------------ END: Condition root group ------------>
      <!------------ START: Condition rendered ------------>
      <div class="text-muted">
        <span>{{ $t("formHelper.condition") }}: </span>
        <code>{{ conditionRendered }}</code>
      </div>
      <!------------ END: Condition rendered ------------>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import { base } from "@/components/Tools/FormHelper/Helper/mixins";
import { defaultOperators } from "@/components/Tools/FormHelper/Helper/constants";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import ConditionGroup from "@/components/Tools/FormHelper/Components/Condition/ConditionGroup";
import { renderConditions } from "@/components/Tools/FormHelper/Helper/functions";
import _ from "lodash";

export default {
  components: { ConditionGroup, FieldWrapper },
  mixins: [base],
  provide: function () {
    return {
      operators: this.field.operators ?? defaultOperators
    };
  },
  props: {},
  data() {
    return {
      isMounted: false,
      key: 1,
      defaultGroup: {
        type: "group",
        operator: "and",
        children: [
          {
            type: "condition",
            field: "",
            operator: "",
            value: "",
            valid: undefined
          }
        ],
        valid: undefined
      }
    };
  },
  computed: {
    conditionRendered: function () {
      return renderConditions(this.value);
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function (newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          this.onInput();
        }
      }
    }
  },
  mounted() {
    this.setDefaultValue();
    this.validate();
    this.isMounted = true;
  },
  methods: {
    setDefaultValue() {
      // Set default group object to prevent errors id necessary
      if (typeof this.value !== "object" || this.value.type !== "group") {
        this.value = this.defaultGroup;
      }
    },
    validate() {
      // Validate whole condition object
      let valid = this.validateGroup(this.value);
      // If condition is same as default group (one empty condition) it's not invalid
      if (!valid && _.isEqual(this.value, this.defaultGroup)) {
        this.value.children[0].valid = undefined;
        valid = true;
      }
      this.key++;
      return valid;
    },
    validateGroup(group) {
      // Set valid as true
      let valid = true;
      // Loop through children
      group.children.forEach(child => {
        // Validate child based on their type, either condition or group
        let childValid =
          child.type === "condition"
            ? this.validateCondition(child)
            : this.validateGroup(child);
        // If child is not valid, update local variable
        if (childValid === false) {
          valid = false;
        }
      });
      // Return valid state
      return valid;
    },
    validateCondition(condition) {
      // Set valid true if all three parts of condition are filled
      let valid = ![
        condition.field,
        condition.operator,
        condition.value
      ].includes("");
      // Set valid state
      this.$set(condition, "valid", valid);
      // Return valid state
      return valid;
    }
  }
};
</script>

<style lang="scss">
.condition-item {
  > .overflow-x-auto {
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
